import axios from "axios";
import store from "../store";

var API_URL = null;
try {
  // eslint-disable-next-line no-undef
  API_URL = MOVEMENT_API_URL;
} catch (e) {
  API_URL = "http://mv.home";
  //API_URL = "http://localhost:8081";
}

const bays = {
  async loadBays(facility) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/facilitylocations?facility=${facility}`;
    return axios.get(url);
  },
  async loadTrailersAtFacility(facility) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/facilitylocations/trailers?facility=${facility}`;
    return axios.get(url);
  },
  async updateBayNote(bay, note) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/facilitylocations/${bay.facility}/${bay.code}/note?by=${store.getters["security/userName"]}`;
    if (note != null) {
      return axios.put(url, note, {
        headers: { "Content-Type": "text/plain" },
      });
    } else {
      return axios.delete(url);
    }
  },
  async updateBayWorkers(bay, workers) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/facilitylocations/${bay.facility}/${bay.code}/workers?by=${store.getters["security/userName"]}`;
    if (workers != null) {
      return axios.put(url, workers, {
        headers: { "Content-Type": "text/plain" },
      });
    } else {
      return axios.delete(url);
    }
  },
};

const driverroutes = {
  async getDriver(email) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/drivers/email?email=${email}`;
    return axios.get(url);
  },
  async getDestinations() {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/destinations`;
    return axios.get(url);
  },
  async findDestinationByName(name) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/destinations/search`;
    let obj = { name: name };
    return axios.post(url, obj, {
      headers: { "Content-Type": "application/json" },
    });
  },
  async findDestinationByAddress(address, city, state) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/destinations/search`;
    let obj = { address: address, city: city, state: state };
    return axios.post(url, obj, {
      headers: { "Content-Type": "application/json" },
    });
  },
  async getActiveDrivers() {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/drivers?active=true`;
    return axios.get(url);
  },
  async getAllDrivers() {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/drivers`;
    return axios.get(url);
  },
  async updateDriver(driver) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/drivers/${driver.id}?by=${store.getters["security/userName"]}`;
    return axios.put(url, driver, {
      headers: { "Content-Type": "application/json" },
    });
  },
  async addDriver(driver) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/drivers?by=${store.getters["security/userName"]}`;
    return axios.post(url, driver, {
      headers: { "Content-Type": "application/json" },
    });
  },
  async getActiveRoutesWithTrailers() {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/driverworkdayroutes/currentwithtrailers`;
    return axios.get(url);
  },
  async getWorkDaysForDate(workDay, driver) {
    await store.dispatch("security/refreshSecurity");
    const url = new URL(`${API_URL}/driverworkdays`);
    if (workDay) {
      url.searchParams.append("date", `${workDay}`);
    }
    if (driver) {
      url.searchParams.append("driverId", `${driver.id}`);
    }
    return axios.get(url);
  },
  async getWorkDayForDriver(driverId, workDay) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/drivers/${driverId}/workday?workDay=${workDay}`;
    return axios.get(url);
  },
  async getRoutesForWorkDay(workDayId) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/driverworkdays/${workDayId}/routes`;
    return axios.get(url);
  },
  async getRoutesForWorkDate(workDay, driver) {
    await store.dispatch("security/refreshSecurity");
    const url = new URL(`${API_URL}/driverworkdayroutes`);
    url.searchParams.append("workDay", `${workDay}`);
    if (driver) {
      url.searchParams.append("driverId", `${driver.id}`);
    }
    return axios.get(url);
  },
  async beginWorkDayForDriver(driver, date) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/drivers/${driver.id}/workday?date=${date}&by=${store.getters["security/userName"]}`;
    return axios.post(url);
  },
  async addRoute(route) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/driverworkdayroutes?by=${store.getters["security/userName"]}`;
    return axios.post(url, route, {
      headers: { "Content-Type": "application/json" },
    });
  },
  async deleteRoute(route) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/driverworkdayroutes/${route.id}?by=${store.getters["security/userName"]}`;
    return axios.delete(url);
  },
  async updateRouteTruck(route) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/driverworkdayroutes/${route.id}/startloc?by=${store.getters["security/userName"]}`;
    return axios.put(url, route.truck, {
      headers: { "Content-Type": "text/plain" },
    });
  },
  async updateRouteStartLoc(route) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/driverworkdayroutes/${route.id}/startloc?by=${store.getters["security/userName"]}`;
    return axios.put(url, route.startLoc, {
      headers: { "Content-Type": "text/plain" },
    });
  },
  async updateRouteEndLoc(route) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/driverworkdayroutes/${route.id}/endloc?by=${store.getters["security/userName"]}`;
    return axios.put(url, route.endLoc, {
      headers: { "Content-Type": "text/plain" },
    });
  },
  async updateRouteNote(route) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/driverworkdayroutes/${route.id}/note?by=${store.getters["security/userName"]}`;
    return axios.put(url, route.note, {
      headers: { "Content-Type": "text/plain" },
    });
  },
  async updateRouteTruck(route) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/driverworkdayroutes/${route.id}/truck?by=${store.getters["security/userName"]}`;
    return axios.put(url, route.truck, {
      headers: { "Content-Type": "text/plain" },
    });
  },
  async updateRouteActivity(route) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/driverworkdayroutes/${route.id}/activity?by=${store.getters["security/userName"]}`;
    return axios.put(url, route.activity, {
      headers: { "Content-Type": "text/plain" },
    });
  },
  async updateRouteTrailer(route) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/driverworkdayroutes/${route.id}/trailer?by=${store.getters["security/userName"]}`;
    return axios.put(url, route.trailer, {
      headers: { "Content-Type": "application/json" },
    });
  },
  async updateRoute(route) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/driverworkdayroutes/${route.id}?by=${store.getters["security/userName"]}`;
    return axios.put(url, route, {
      headers: { "Content-Type": "application/json" },
    });
  },
  async updateRouteEdi(route) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/driverworkdayroutes/${route.id}?by=${store.getters["security/userName"]}&edi=true`;
    return axios.put(url, route, {
      headers: { "Content-Type": "application/json" },
    });
  },
  async startRoute(route) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/driverworkdayroutes/${route.id}/start?by=${store.getters["security/userName"]}`;
    return axios.post(url);
  },
  async endRoute(route) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/driverworkdayroutes/${route.id}/end?by=${store.getters["security/userName"]}`;
    return axios.post(url);
  },
  async deleteWorkDay(workDay) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/driverworkdays/${workDay.id}?by=${store.getters["security/userName"]}`;
    return axios.delete(url);
  },
};

const spotter = {
  async loadOpen(facility) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/spotmovements/open/${facility}`;
    return axios.get(url);
  },
  async claim(id) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/spotmovements/${id}/claim?by=${store.getters["security/userName"]}`;
    return axios.post(url);
  },
  async release(id) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/spotmovements/${id}/release?by=${store.getters["security/userName"]}`;
    return axios.post(url);
  },
  async complete(id) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/spotmovements/${id}/complete?by=${store.getters["security/userName"]}`;
    return axios.post(url);
  },
  async requestMove(req) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/spotmovements?by=${store.getters["security/userName"]}`;
    return axios.post(url, req, {
      headers: { "Content-Type": "application/json" },
    });
  },
  async remove(id) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/spotmovements/${id}?by=${store.getters["security/userName"]}`;
    return axios.delete(url);
  },
  async priority(id, priority) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/spotmovements/${id}/priority?by=${store.getters["security/userName"]}&isPriority=${priority}`;
    return axios.post(url);
  },
};

const trailers = {
  async loadTrailers() {
    await store.dispatch("security/refreshSecurity");
    const url = API_URL + "/trailers?scac=QUCF";
    return axios.get(url);
  },
  async loadFacilityLocations() {
    await store.dispatch("security/refreshSecurity");
    const url = API_URL + "/facilitylocations";
    return axios.get(url);
  },
  async findTrailer(scac, id) {
    await store.dispatch("security/refreshSecurity");
    const url = API_URL + `/trailers/${scac}/${id}`;
    return axios.get(url);
  },
  async findLocation(facility, code) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/facilitylocations/${facility}/${code}`;
    return axios.get(url);
  },
  async setRedTag(scac, id, redTag, note) {
    await store.dispatch("security/refreshSecurity");
    const url =
      API_URL +
      `/trailers/${scac}/${id}/redtag?by=` +
      store.getters["security/userName"];
    if (redTag) {
      return axios.put(url, note, {
        headers: { "Content-Type": "text/plain" },
      });
    } else {
      return axios.delete(url);
    }
  },
  async setYellowTag(scac, id, yellowTag, note) {
    await store.dispatch("security/refreshSecurity");
    const url =
      API_URL +
      `/trailers/${scac}/${id}/yellowtag?by=` +
      store.getters["security/userName"];
    if (yellowTag) {
      return axios.put(url, note, {
        headers: { "Content-Type": "text/plain" },
      });
    } else {
      return axios.delete(url);
    }
  },
  async setStatus(scac, id, status, makeEligibleForDetention = false) {
    await store.dispatch("security/refreshSecurity");
    const url =
      API_URL +
      `/trailers/${scac}/${id}/status?by=${store.getters["security/userName"]}&makeEligibleForDetention=${makeEligibleForDetention}`;
    if (status) {
      return axios.put(url, status, {
        headers: { "Content-Type": "text/plain" },
      });
    } else {
      return axios.delete(url);
    }
  },
  async setNote(scac, id, note) {
    await store.dispatch("security/refreshSecurity");
    const url =
      API_URL +
      `/trailers/${scac}/${id}/note?by=` +
      store.getters["security/userName"];
    if (note) {
      return axios.put(url, note, {
        headers: { "Content-Type": "text/plain" },
      });
    } else {
      return axios.delete(url);
    }
  },
  async setFacilityLocationNote(facility, code, note) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/facilitylocations/${facility}/${code}/note?by=${store.getters["security/userName"]}`;
    if (note) {
      return axios.put(url, note, {
        headers: { "Content-Type": "text/plain" },
      });
    } else {
      return axios.delete(url);
    }
  },
  async setExternalLocation(scac, id, loc) {
    await store.dispatch("security/refreshSecurity");
    const url =
      API_URL +
      `/trailers/${scac}/${id}/externallocation?by=` +
      store.getters["security/userName"];
    if (loc) {
      return axios.put(url, loc, { headers: { "Content-Type": "text/plain" } });
    } else {
      return axios.delete(url);
    }
  },
  async setFacilityLocation(scac, id, facilityLocation) {
    await store.dispatch("security/refreshSecurity");
    if (facilityLocation) {
      const url =
        API_URL +
        `/trailers/${scac}/${id}/facilitylocation/${facilityLocation.facility}/${facilityLocation.code}?by=` +
        store.getters["security/userName"];
      return axios.put(url);
    } else {
      const url =
        API_URL +
        `/trailers/${scac}/${id}/facilitylocation?by=` +
        store.getters["security/userName"];
      return axios.delete(url);
    }
  },
  async addTrailer(trailer) {
    await store.dispatch("security/refreshSecurity");
    const url = API_URL + "/trailers?by=" + store.getters["security/userName"];
    return axios.post(url, trailer, {
      headers: { "Content-Type": "application/json" },
    });
  },
  async deleteTrailer(scac, id) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/trailers/${scac}/${id}?by=${store.getters["security/userName"]}`;
    return axios.delete(url);
  },
  async addFacilityLocation(facilityLocation) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/facilitylocations?by=${store.getters["security/userName"]}`;
    return axios.post(url, facilityLocation, {
      headers: { "Content-Type": "application/json" },
    });
  },
};

const yard = {
  async loadStays(facility) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/trailerstays/facility/${facility}`;
    return axios.get(url);
  },
  async loadStay(id) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/trailerstays/${id}`;
    return axios.get(url);
  },
  async stayEnded(stay) {
    if (stay.propsJson) {
      stay.propsJson = JSON.stringify(stay.propsJson);
    }
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/trailerstays/${stay.id}/end?by=${store.getters["security/userName"]}`;
    return axios.post(url, stay, {
      headers: { "Content-Type": "application/json" },
    });
  },
  async beginStay(stay) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/trailerstays?by=${store.getters["security/userName"]}`;
    return axios.post(url, stay, {
      headers: { "Content-Type": "application/json" },
    });
  },
  async loadLastMoves(facility) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/trailerstays/lastspotmoves?facility=${facility}`;
    return axios.get(url);
  },
  async updateStay(stay) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/trailerstays/${stay.id}?by=${store.getters["security/userName"]}`;
    return axios.put(url, stay, {
      headers: { "Content-Type": "application/json" },
    });
  },
  async loadLookupInboundContents(facility) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/lookups/values?key=INBOUND CONTENT&facility=${facility}`;
    return axios.get(url);
  },
  async loadLookupInboundDests(facility) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/lookups/values?key=INBOUND DEST&facility=${facility}`;
    return axios.get(url);
  },
  async loadLookupInboundOrigins(facility) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/lookups/values?key=INBOUND ORIGIN&facility=${facility}`;
    return axios.get(url);
  },
  async setInboundEmpty(stay) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/trailerstays/${stay.id}/empty?by=${store.getters["security/userName"]}`;
    return axios.post(url);
  },
  async setInboundLoaded(stay) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/trailerstays/${stay.id}/loaded?by=${store.getters["security/userName"]}`;
    return axios.post(url);
  },
};

const lookups = {
  async lookup(facility, key) {
    await store.dispatch("security/refreshSecurity");
    const url = `${API_URL}/lookups/values?facility=${facility}&key=${key}`;
    return axios.get(url);
  },
};
export { bays, driverroutes, spotter, trailers, yard, lookups };
