/* eslint-disable no-empty-pattern */
import {
  driverroutes as routesApi,
  trailers as trailerApi,
  lookups
} from "../../api/movementapi";
import dateutil from "../../dateutil";
import Vue from "vue";
import moment from "moment";
/*
function sortDriversByLastNameFirstName(drivers) {
  return drivers.sort((d1, d2) => {
    let r = d1.lastName.localeCompare(d2.lastName);
    if (r == 0) {
      r = d1.firstName.localeCompare(d2.firstName);
    }
    return r;
  });
}
*/
function sortDriversByFirstNameLastName(drivers) {
  return drivers.sort((d1, d2) => {
    return compareDriversSortByFirstNameLastName(d1, d2);
  });
}
/*
function sortWorkDaysByLastNameFirstName(workDays) {
  return workDays.sort((d1, d2) => {
    let r = d1.driver.lastName.localeCompare(d2.driver.lastName);
    if (r == 0) {
      r = d1.driver.firstName.localeCompare(d2.driver.firstName);
    }
    return r;
  });
}
*/
function sortWorkDaysByFirstNameLastName(workDays) {
  return workDays.sort((d1, d2) => {
    return compareDriversSortByFirstNameLastName(d1.driver, d2.driver);
  });
}
function compareDriversSortByFirstNameLastName(d1, d2) {
  let r = d1.firstName.localeCompare(d2.firstName);
  if (r == 0) {
    r = d1.lastName.localeCompare(d2.lastName);
  }
  return r;
}

const state = {
  drivers: [],
  workDays: [],
  routes: [],
  destinations: [],
  trucks: [],
  workDay: null,
  globalHideEnded: false,
  selectedDriverWorkDay: null,
  selectedDriverRoute: null,
  driver: null,
};

const getters = {
  driver: (state) => {
    return state.driver;
  },
  trucks: (state) => {
    return state.trucks;
  },
  selectedDriverWorkDay: (state) => {
    return state.selectedDriverWorkDay;
  },
  selectedDriverRoute: (state) => {
    return state.selectedDriverRoute;
  },
  globalHideEnded: (state) => {
    return state.globalHideEnded;
  },
  destinations: (state) => {
    return state != null ? state.destinations : null;
  },
  routesForDriver: (state) => (driver) => {
    return state != null && state.routes != null
      ? state.routes
          .filter((r) => r.driverWorkDay.driver.id === driver.id)
          .sort((r1, r2) => r1.routeNo - r2.routeNo)
      : null;
  },
  workDayForDriver: (state) => (driverId) => {
    return state != null && state.workDays != null
      ? state.workDays.find((wd) => wd.driver.id === driverId)
      : null;
  },
  driversWithWorkDay: (state, getters) => {
    let res = [];
    if (state.workDays != null && state.drivers != null) {
      state.workDays.forEach((wd) => res.push(getters.driver(wd.driver.id)));
      res = sortDriversByFirstNameLastName(res);
    }
    return res;
  },
  driversWithoutWorkDay: (state) => {
    let res = [];
    if (state.workDays != null && state.drivers != null) {
      let dIds = state.workDays.map((wd) => wd.driver.id);
      res = state.drivers.filter((d) => dIds.indexOf(d.id) < 0);
      res = sortDriversByFirstNameLastName(res);
    }
    return res;
  },
  getDriver: (state) => (driverId) => {
    return state.drivers.find((d) => d.id === driverId);
  },
  getDrivers: (state) => {
    return sortDriversByFirstNameLastName(state.drivers);
  },
  workDays: (state) => {
    return sortWorkDaysByFirstNameLastName(state.workDays);
  },
  workDay: (state) => {
    return state.workDay;
  },
  readOnly: (state) => {
    let result = true;
    if (state.workDay != null) {
      let n = dateutil.localNowFormatted("YYYY-MM-DD");
      let wdMoment = moment(state.workDay);
      let nMoment = moment(n);
      let days = nMoment.diff(wdMoment, "days");
      result = days > 1;
    }
    return result;
  },
  routes: (state) => {
    return state.routes;
  },
};

const actions = {
  async setDriver({ commit }, email) {
    if (email) {
      return routesApi.getDriver(email).then((res) => {
        commit("setDriver", res.data.payload);
      });
    } else {
      commit("setDriver", null);
    }
  },
  setSelectedDriverWorkDay({ commit }, dwd) {
    commit("setSelectedDriverWorkDay", dwd);
  },
  setSelectedDriverRoute({ commit }, route) {
    commit("setSelectedDriverRoute", route);
  },
  async toggleGlobalHideEnded({ getters, commit }) {
    commit("setGlobalHideEnded", !getters.globalHideEnded);
  },
  async clearData({ commit }) {
    commit("clearData");
  },
  async loadLookups({ commit, dispatch }) {
    let promDest = routesApi
      .getDestinations()
      .then((res) => commit("setDestinations", res.data.payload));
    let t = lookups.lookup("ADC","TRUCK").then(res => {
      commit("setTrucks", res.data.payload);
    });
    let all = Promise.all([promDest,t]);
    return dispatch("common/showLoadingDuringAction", all, { root: true });
  },
  async updateRoutes({ commit, getters }) {
    routesApi
      .getRoutesForWorkDate(getters.workDay, getters.driver)
      .then((res) => {
        if (res.data.payload != null) {
          let curRoutes = getters.routesForDriver(getters.driver);
          let curRouteIds = curRoutes.map((r) => r.id);
          res.data.payload.forEach((r) => {
            let idx = curRouteIds.indexOf(r.id);
            if (idx > -1) {
              curRouteIds.splice(idx, 1);
              commit("updateRoute", r);
            } else {
              commit("addRoute", r);
            }
          });
          curRouteIds.forEach((rId) => {
            commit(
              "removeRoute",
              curRoutes[curRoutes.map((r) => r.id).indexOf(rId)],
            );
          });
        }
      });
  },
  async loadInitialData({ dispatch, commit, getters }) {
    dispatch("common/setErrorMessage", null, { root: true });
    dispatch("common/setLoading", true, { root: true });
    await routesApi.getActiveDrivers().then((res) => {
      commit("setInitialDrivers", res.data.payload);
    });
    await routesApi
      .getWorkDaysForDate(getters.workDay, getters.driver)
      .then((res) => {
        if (res.data.payload != null) {
          if (Array.isArray(res.data.payload)) {
            res.data.payload.forEach((wd) => commit("addWorkDay", wd));
          } else {
            commit("addWorkDay", res.data.payload);
          }
        }
      })
      .catch(() => {
        //Noop
      });
    let promRoutes = routesApi
      .getRoutesForWorkDate(getters.workDay, getters.driver)
      .then((res) => {
        if (res.data.payload != null) {
          res.data.payload.forEach((r) => {
            commit("addRoute", r);
          });
        }
      });
    return Promise.all([promRoutes])
      .catch((err) => {
        dispatch("common/handleHttpError", err, {
          root: true,
        });
      })
      .finally(() => {
        dispatch("common/setLoading", false, { root: true });
      });
  },
  async setWorkDay({ commit, dispatch }, { workDay, driver }) {
    await commit("clearData");
    await commit("setWorkDay", workDay);
    return dispatch("loadInitialData");
  },
  async beginWorkDayForDriver({ dispatch, getters }, driver) {
    return dispatch(
      "common/showLoadingDuringAction",
      routesApi.beginWorkDayForDriver(driver, getters.workDay),
      { root: true },
    );
  },
  async deleteWorkDay({ dispatch }, workDay) {
    return dispatch(
      "common/showLoadingDuringAction",
      routesApi.deleteWorkDay(workDay),
      { root: true },
    );
  },
  async addRoute({ dispatch }, route) {
    return dispatch(
      "common/showLoadingDuringAction",
      routesApi.addRoute(route),
      { root: true },
    );
  },
  async deleteRoute({ dispatch }, route) {
    return dispatch(
      "common/showLoadingDuringAction",
      routesApi.deleteRoute(route),
      { root: true },
    );
  },
  async updateRouteStartLoc({ dispatch }, route) {
    return dispatch(
      "common/showLoadingDuringAction",
      routesApi.updateRouteStartLoc(route),
      { root: true },
    );
  },
  async updateRouteEndLoc({ dispatch }, route) {
    return dispatch(
      "common/showLoadingDuringAction",
      routesApi.updateRouteEndLoc(route),
      { root: true },
    );
  },
  async updateRouteNote({ dispatch }, route) {
    return dispatch(
      "common/showLoadingDuringAction",
      routesApi.updateRouteNote(route),
      { root: true },
    );
  },
  async updateRouteActivity({ dispatch }, route) {
    return dispatch(
      "common/showLoadingDuringAction",
      routesApi.updateRouteActivity(route),
      { root: true },
    );
  },
  async updateRouteTrailer({ dispatch }, route) {
    return dispatch(
      "common/showLoadingDuringAction",
      routesApi.updateRouteTrailer(route),
      { root: true },
    );
  },
  async updateRouteTruck({ dispatch }, route) {
    return dispatch(
      "common/showLoadingDuringAction",
      routesApi.updateRouteTruck(route),
      { root: true },
    );
  },
  async updateRouteEdi({ dispatch }, route) {
    return dispatch(
      "common/showLoadingDuringAction",
      routesApi.updateRouteEdi(route),
      { root: true },
    );
  },
  async updateRoute({ dispatch }, route) {
    return dispatch(
      "common/showLoadingDuringAction",
      routesApi.updateRoute(route),
      { root: true },
    );
  },
  async startRoute({ dispatch }, route) {
    return dispatch(
      "common/showLoadingDuringAction",
      routesApi.startRoute(route),
      { root: true },
    );
  },
  async endRoute({ dispatch }, route) {
    return dispatch(
      "common/showLoadingDuringAction",
      routesApi.endRoute(route),
      { root: true },
    );
  },
  async findTrailer({ dispatch }, trailer) {
    dispatch("common/setErrorMessage", null, { root: true });
    dispatch("common/setLoading", true, { root: true });
    return trailerApi
      .findTrailer(trailer.scac, trailer.id)
      .catch((err) => {
        dispatch("common/handleHttpError", err, {
          root: true,
        });
      })
      .finally(() => {
        dispatch("common/setLoading", false, { root: true });
      });
  },
  handleDriverMutation({}) {
    // NOOP let mutation = JSON.parse(msg.body);
  },
  handleTrailerMutations({ commit }, msg) {
    let mutation = JSON.parse(msg.body);
    if (mutation != null && mutation.current != null) {
      commit("updateTrailer", mutation.current);
    }
  },
  handleDriverWorkDayMutation({ commit }, msg) {
    let mutation = JSON.parse(msg.body);
    //driverWorkDayCreated, driverWorkDayUpdated, driverWorkDayDeleted, driverWorkDayRoutesUpdated
    switch (mutation.action) {
      case "driverWorkDayCreated":
        commit("addWorkDay", mutation.current);
        break;
      case "driverWorkDayUpdated":
        commit("updateWorkDay", mutation.current);
        break;
      case "driverWorkDayDeleted":
        commit("removeWorkDay", mutation.previous);
        break;
      case "driverWorkDayRoutesUpdated":
        break;
    }
    //console.log(mutation);
  },
  handleDriverWorkDayRouteMutation({ commit }, msg) {
    let mutation = JSON.parse(msg.body);
    //driverWorkDayRouteCreated, driverWorkDayRouteUpdated, driverWorkDayRouteDeleted, driverWorkDayRouteStarted, driverWorkDayRouteEnded
    switch (mutation.action) {
      case "driverWorkDayRouteCreated":
        commit("addRoute", mutation.current);
        break;
      case "driverWorkDayRouteUpdated":
        commit("updateRoute", mutation.current);
        break;
      case "driverWorkDayRouteDeleted":
        commit("removeRoute", mutation.previous);
        break;
      case "driverWorkDayRouteStarted":
        commit("updateRoute", mutation.current);
        break;
      case "driverWorkDayRouteEnded":
        commit("updateRoute", mutation.current);
        break;
    }
    //console.log(mutation);
  },
};

const mutations = {
  setDriver(state, driver) {
    state.driver = driver;
  },
  setTrucks(state, trucks) {
    state.trucks = trucks;
  },
  setSelectedDriverWorkDay(state, dwd) {
    state.selectedDriverWorkDay = dwd;
  },
  setSelectedDriverRoute(state, route) {
    state.selectedDriverRoute = route;
  },
  clearData(state) {
    state.workDay = null;
    state.routes = [];
    state.workDays = [];
    state.drivers = [];
    state.globalHideEnded = false;
  },
  setGlobalHideEnded(state, data) {
    state.globalHideEnded = data;
  },
  setDestinations(state, data) {
    state.destinations = data;
  },
  setWorkDay(state, workDay) {
    state.workDay = workDay;
  },
  setInitialDrivers(state, drivers) {
    state.drivers = drivers;
  },
  addWorkDay(state, workDay) {
    state.workDays.push(workDay);
  },
  removeWorkDay(state, workDay) {
    let idx = state.workDays.map((wd) => wd.id).indexOf(workDay.id);
    if (idx > -1) {
      state.workDays.splice(idx, 1);
    }
  },
  updateWorkDay(state, workDay) {
    let idx = state.workDays.map((wd) => wd.id).indexOf(workDay.id);
    if (idx > -1) {
      Vue.set(state.workDays, idx, workDay);
    }
  },
  updateTrailer(state, trailer) {
    let id = `${trailer.scac}${trailer.id}`;
    let updRoutes = state.routes.filter(
      (r) =>
        r.trailer != null &&
        `${r.trailer.scac}${r.trailer.id}` === id &&
        r.endTime == null,
    );
    updRoutes.forEach((r) => {
      let t = r.trailer;
      t.status = trailer.status;
    });
  },
  addRoute(state, route) {
    state.routes.push(route);
  },
  removeRoute(state, route) {
    let idx = state.routes.map((r) => r.id).indexOf(route.id);
    if (idx > -1) {
      state.routes.splice(idx, 1);
    }
  },
  updateRoute(state, route) {
    let idx = state.routes.map((r) => r.id).indexOf(route.id);
    if (idx > -1) {
      Vue.set(state.routes, idx, route);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
