<template>
  <b-row :no-gutters="true" class="myRow" style="border: solid black 1px">
    <b-col md="2">
      <FileUploadIcon :title="uploadTitle" :id="route.id" type="route" @files-updated="attachmentsUpdated" />
      <span class="float-right">
        <span v-if="
          route.edi204ShipmentId == null &&
          route.endLoc != null &&
          route.trailer != null
        ">
          <font-awesome-icon title="Assign Load Tender to Route" icon="file-invoice"
            @click="assignTenderToRouteClicked" />
        </span>
        <span v-if="route.edi204ShipmentId != null">
          <font-awesome-icon icon="print" @click="printShipmentClicked(route.edi204Id)" />&nbsp;
          <span class="clickable" @click="shipmentIdClicked(route.edi204Id)">{{
            route.edi204ShipmentId
          }}</span>&nbsp;
          <font-awesome-icon icon="window-close" @click="remoteTenderFromRouteClicked" />
        </span>
        $
        <input @change="billableBlur" :disabled="started || ended || readOnly" type="checkbox"
          v-model="route.billable" />
        {{ route.routeNo }}
      </span>
    </b-col>
    <b-col md="1">
      <select :disabled="started || ended || readOnly" @change="startLocBlur"
        class="form-control form-control-sm text-uppercase" v-model="route.startLoc"
        :title="locDetails(route.startLoc)">
        <option v-for="dest in destinations" :key="dest.code" :title="locDetails(dest.code)">
          {{ dest.code }}
        </option>
      </select>
    </b-col>
    <b-col md="1">
      <select :disabled="started || ended || readOnly" class="form-control form-control-sm" @change="activityChanged"
        v-model="route.activity">
        <option :value="null">-- NONE --</option>
        <option>TRANSITING</option>
        <option>FUELING</option>
        <option>BREAK</option>
        <option>LUNCH</option>
        <option>SPOTTING</option>
        <option>WASHING</option>
      </select>
    </b-col>
    <b-col md="1">
      <input :disabled="ended || readOnly" @blur="noteBlur" @keyup="setDataChanged"
        class="form-control form-control-sm text-uppercase" type="text" v-model.trim="route.note" />
    </b-col>
    <b-col md="1">
      <b-form-select :disabled="ended || readOnly" v-model="route.truck" :options="trucks" value-field="item"
        text-field="item" @change="truckBlur">
        <template #first>
          <b-form-select-option value="" disabled>-- Select Truck --</b-form-select-option>
        </template>
      </b-form-select>
    </b-col>
    <b-col md="2" style="white-space: nowrap">
      <input :disabled="started || ended || readOnly" @blur="trailerScacIdBlur" @keyup="setTrailerScacIdChanged"
        class="text-uppercase" type="text" v-model.trim="trailerScacId" />
      <button v-show="trailer" :disabled="trailer == null || started || readOnly" @click="showUpdateTrailerStatus">
        <TrailerLoadedDisplay :pr="8" :pl="8" :loadedOverride="statusOverride" :trailer="trailer" />
      </button>
      <span v-show="trailer && route.trailerStatus == null" style="font-weight: bold">*</span>
      <button title="Clear Trailer" @click="clearTrailer" v-show="trailer && !started && !readOnly" class="btn">
        <font-awesome-icon icon="window-close" />
      </button>
    </b-col>
    <b-col md="1">
      <select :disabled="ended || readOnly" @change="endLocBlur" class="form-control form-control-sm text-uppercase"
        v-model="route.endLoc" :title="locDetails(route.endLoc)">
        <option v-for="dest in destinations" :key="dest.code" :title="locDetails(dest.code)">
          {{ dest.code }}
        </option>
      </select>
    </b-col>
    <b-col md="3">
      <b-row>
        <b-col md="6" style="font-size: smaller">
          {{ displayWait }}
          <span v-if="displayWait != null && route.startTime == null" style="font-weight: bold">*</span>
          <span v-if="displayStartTime != null">/ {{ displayStartTime }}</span>
          <span v-if="displayEndTime != null">/ {{ displayEndTime }}</span>
          <span v-if="displayDuration != null">/ {{ displayDuration }}</span>
        </b-col>
        <b-col md="6">
          <b-row :no-gutters="true">
            <b-col class="col" :class="waitingRunningClass" style="padding: 0px">
              <span v-show="canStart && !readOnly">{{ driver.firstName }}
                {{ driver.lastName.substring(1, 0) }} Waiting to Start</span>
              <span v-show="started && !ended && !readOnly">{{ driver.firstName }}
                {{ driver.lastName.substring(1, 0) }} Running Route</span>
              &nbsp;
            </b-col>
            <b-col class="col-auto" style="padding: 0px">
              <!-- class="col-auto" -->
              <button title="Save Route" class="btn" v-show="canSave" @click="updateRoute(route)">
                <font-awesome-icon icon="save" />
              </button>
              <button title="Start Route" class="btn" v-show="canStart && !readOnly" @click="startRoute(route)">
                <font-awesome-icon icon="play-circle" />
              </button>
              <button title="End Route" class="btn" v-show="started && !ended" @click="endRoute(route)">
                <font-awesome-icon icon="stop-circle" />
              </button>
              <button title="Delete Route" class="btn" v-show="!started && !readOnly && !hasAttachments"
                @click="deleteRoute(route)">
                <font-awesome-icon icon="window-close" />
              </button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import textutils from "../../textutils";
import dateutil from "../../dateutil";
import moment from "moment";
import TrailerLoadedDisplay from "../trailer/TrailerLoadedDisplay.vue";
import FileUploadIcon from "../common/FileUploadIcon.vue";
import { edi } from "../../api/integrationapi";

//{type: 'route', id: route.id, title: `${route.driverWorkDay.driver.firstName} ${route.driverWorkDay.driver.lastName}'s Route`}
export default {
  components: {
    TrailerLoadedDisplay,
    FileUploadIcon,
  },
  props: {
    route: Object,
    driver: Object,
  },
  data() {
    return {
      trailerScacId:
        this.route.trailer != null
          ? `${this.route.trailer.scac} ${this.route.trailer.id}`
          : null,
      dataChanged: false,
      startTimeForWait: null,
      displayWait: null,
      displayDuration: null,
      timerHandle: null,
      trailerScacIdChanged: false,
      attachmentCount: 0,
    };
  },
  computed: {
    ...mapGetters("common", ["errorMessage"]),
    ...mapGetters("routes", ["routesForDriver", "readOnly", "destinations", "trucks"]),
    uploadTitle() {
      return `Documents for ${this.route.driverWorkDay.driver.firstName} ${this.route.driverWorkDay.driver.lastName}'s Route ${this.route.routeNo}`;
    },
    trailer() {
      return this.route.trailer != null ? this.route.trailer : null;
    },
    started() {
      return this.route.startTime != null;
    },
    ended() {
      return this.route.endTime != null;
    },
    routes() {
      return this.routesForDriver(this.route.driverWorkDay.driver);
    },
    hasAttachments() {
      return this.attachmentCount > 0;
    },
    prevRoute() {
      let prev = null;
      if (this.routes != null) {
        prev = this.routes.find((r) => r.routeNo == this.route.routeNo - 1);
      }
      return prev;
    },
    statusOverride() {
      let s = null;
      if (this.route.trailerStatus != null) {
        s = this.route.trailerStatus;
      }
      return s;
    },
    canStart() {
      let prevEnd =
        this.prevRoute != null ? this.prevRoute.endTime != null : true;
      let validRoute =
        this.route.startLoc != null &&
        this.route.activity != null &&
        this.route.endLoc != null;
      let started = this.route.startTime != null;
      let saved = !this.dataChanged;
      return (
        prevEnd && validRoute && !started && saved && !this.trailerScacIdChanged
      );
    },
    waitingRunningClass() {
      let c = null;
      if (this.started && !this.ended) {
        c = "routeStartedLabel";
      }
      if (this.canStart && !this.readOnly) {
        c = "routeCanStartLabel";
      }
      return c;
    },
    canSave() {
      return (
        !this.started &&
        !this.trailerScacIdChanged &&
        this.dataChanged &&
        !this.readOnly
      );
    },
    displayStartTime() {
      return this.route.startTime != null
        ? dateutil.getLocalDateTime(this.route.startTime, "h:mm a")
        : null;
    },
    displayEndTime() {
      let r = null;
      if (this.route.startTime != null) {
        if (this.route.endTime != null) {
          r = dateutil.getLocalDateTime(this.route.endTime, "h:mm a");
        } else {
          r = "--:--";
        }
      }
      return r;
    },
    trailerScacIdValid() {
      let v = textutils.toUpper(this.trailerScacId);
      let r = true;
      if (v != null) {
        let parts = v.split(" ");
        if (parts.length != 2) {
          r = false;
        }
      }
      return r;
    },
  },
  watch: {
    route(newVal) {
      if (newVal != null) {
        this.dataChanged = false;
      }
    },
    trailer(newVal) {
      if (newVal != null) {
        this.trailerScacId = `${this.route.trailer.scac} ${this.route.trailer.id}`;
      } else {
        this.trailerScacId = null;
      }
    },
    routes() {
      this.calcStartTimeForWait();
    },
  },
  methods: {
    ...mapActions("common", ["setErrorMessage"]),
    ...mapActions("routes", [
      "deleteRoute",
      "updateRouteStartLoc",
      "updateRouteActivity",
      "updateRouteEndLoc",
      "updateRouteNote",
      "updateRouteTrailer",
      "updateRouteTruck",
      "updateRoute",
      "updateRouteEdi",
      "startRoute",
      "endRoute",
      "findTrailer",
      "setSelectedDriverRoute",
    ]),
    assignTenderToRouteClicked() {
      this.setSelectedDriverRoute(this.route);
      this.$bvModal.show("addLadingRouteModal");
    },
    remoteTenderFromRouteClicked() {
      let str = JSON.stringify(this.route);
      let copy = JSON.parse(str);
      copy.edi204Id = null;
      copy.edi204ShipmentId = null;
      this.updateRouteEdi(copy);
    },
    shipmentIdClicked(id) {
      window.open(edi.generateEdi204SheetLink(id), "_new");
    },
    printShipmentClicked(id) {
      window.open(`${edi.generateEdi204PdfSheetLink(id)}?inline=true`, "_new");
    },
    locDetails(loc) {
      let d = null;
      if (loc) {
        let y = this.destinations.find((x) => x.code == loc);
        if (y) {
          d = `${y.name}\n${y.address}\n${y.city}, ${y.state} ${y.zip}`;
        }
      }
      return d;
    },
    attachmentsUpdated(x) {
      this.attachmentCount = x;
    },
    showUpdateTrailerStatus() {
      let obj = {
        title: `Update status for ${this.trailer.scac} ${this.trailer.id}`,
        trailer: this.trailer,
      };
      this.$modal.show("updateTrailerStatusModal", obj);
    },
    setDataChanged() {
      this.dataChanged = true;
    },
    startLocBlur() {
      this.dataChanged = true;
      this.route.startLoc = textutils.toUpper(this.route.startLoc);
      if (this.started) {
        this.updateRouteStartLoc(this.route);
      }
    },
    endLocBlur() {
      this.dataChanged = true;
      this.route.endLoc = textutils.toUpper(this.route.endLoc);
      if (this.started) {
        this.updateRouteEndLoc(this.route);
      }
    },
    billableBlur() {
      this.dataChanged = true;
    },
    noteBlur() {
      this.route.note = textutils.toUpper(this.route.note);
      if (this.started) {
        this.updateRouteNote(this.route);
      }
    },
    truckBlur() {
      this.route.truck = textutils.toUpper(this.route.truck);
      if (!this.ended) {
        this.updateRouteTruck(this.route);
        this.dataChanged = true;
      }
    },
    activityChanged() {
      this.dataChanged = true;
      if (this.started) {
        this.updateRouteActivity(this.route);
      }
      if (this.route.activity != null) {
        switch (this.route.activity) {
          case "FUELING":
          case "ON BREAK":
          case "ON LUNCH":
            this.route.endLoc = this.route.startLoc;
            break;
        }
      }
    },
    clearTrailer() {
      this.trailerScacId = null;
      this.trailerScacIdBlur();
    },
    setTrailerScacIdChanged() {
      this.trailerScacIdChanged = true;
    },
    setTrailerScacId(res) {
      this.trailerScacId = `${res.data.payload.scac} ${res.data.payload.id}`;
      this.trailerScacIdBlur();
    },
    async trailerScacIdBlur() {
      this.trailerScacIdChanged = false;
      if (this.errorMessage != null) {
        this.setErrorMessage(null);
      }
      if (this.trailerScacIdValid) {
        if (
          this.trailerScacId != null &&
          this.trailerScacId.trim().length > 0
        ) {
          let v = textutils.toUpper(this.trailerScacId);
          let parts = v.split(" ");
          let t = { scac: parts[0].trim(), id: parts[1].trim() };
          let res = await this.findTrailer(t);
          if (res) {
            this.route.trailer = res.data.payload;
            this.dataChanged = true;
          } else {
            let obj = {
              scac: t.scac,
              id: t.id,
              title: "Add Unknown Trailer",
              status: "EMPTY",
              callback: this.setTrailerScacId,
            };
            this.trailerScacId = null;
            this.$modal.show("addTrailerModal", obj);
          }
        } else {
          this.route.trailer = null;
          this.dataChanged = true;
        }
      } else {
        this.trailerScacId = null;
        this.route.trailer = null;
        this.setErrorMessage(
          "Trailer must have be in the format of: SCAC TRAILERNO",
        );
      }
      if (this.started) {
        this.updateRouteTrailer(this.route);
      }
    },
    calcStartTimeForWait() {
      this.displayDuration;
      if (this.route.startTime != null) {
        this.startTimeForWait = moment(this.route.startTime);
      } else {
        this.startTimeForWait = moment();
      }
      let w = null;
      if (this.prevRoute != null) {
        if (this.prevRoute.endTime != null) {
          let s = moment(this.prevRoute.endTime);
          let e = this.startTimeForWait;
          w = moment.duration(e.diff(s)).format("h:mm");
        }
      } else {
        w = moment.duration(0).format("h:mm");
      }
      this.displayWait = w;
    },
    calcDuration() {
      let d = null;
      if (this.route.startTime != null) {
        let s = moment(this.route.startTime);
        let e =
          this.route.endTime != null ? moment(this.route.endTime) : moment();
        d = moment.duration(e.diff(s)).format("h:mm");
      }
      this.displayDuration = d;
    },
    onTick() {
      this.calcStartTimeForWait();
      this.calcDuration();
    },
  },
  mounted() {
    this.onTick();
    this.timerHandle = window.setInterval(this.onTick, 1000 * 60);
  },
  beforeDestroy() {
    window.clearInterval(this.timerHandle);
  },
};
</script>

<style scoped>
.btn {
  background-color: lightgrey;
}

.btn:hover {
  background-color: lightgreen;
}

.myRow:hover {
  background-color: antiquewhite;
}

.routeCanStartLabel {
  background-color: yellow;
}

.routeStartedLabel {
  background-color: green;
  color: white;
}
</style>
