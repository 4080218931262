<template>
  <b-card class="myCard" style="padding: 5px">
    <b-card>
      <b-card-header>
        <b-row>
          <b-col md="2" style="padding-left: 0px; padding-right: 0px">
            <font-awesome-icon title="Hide Ended Routes" icon="eye" v-show="!hideEnded" @click="toggleHideEnded" />
            <font-awesome-icon title="Show Ended Routes" icon="eye-slash" v-show="hideEnded"
              @click="toggleHideEnded" />&nbsp;
            <font-awesome-icon :title="showRoutesTitle" @click="toggleShowRoutes" :icon="showRoutesIcon" />&nbsp;
            <font-awesome-icon v-show="!readOnly" title="Add Route" @click="doAddRoute" icon="plus-square" />&nbsp;
            <font-awesome-icon v-show="!readOnly" title="Add Lading Route" @click="doAddLadingRoute" icon="dollar-sign"
              swap-opacity />
            {{ driver.firstName }} {{ driver.lastName.substring(1, 0) }} ({{ driver.defaultTruck }})
          </b-col>
          <b-col md="1">Origin</b-col>
          <b-col md="1">Activity</b-col>
          <b-col md="1">Note</b-col>
          <b-col md="1">Truck</b-col>
          <b-col md="2">Trailer</b-col>
          <b-col md="1">Dest</b-col>
          <b-col md="3">
            <b-row>
              <b-col md="6">Wait / Start / End / Duration</b-col>
              <b-col md="6">
                <span v-show="workDayStarted">
                  T {{ displayTotalTime }} | A {{ displayActivityTime }} | W
                  {{ displayWaitTime }}
                </span>
                <span v-show="!workDayStarted && !readOnly" title="Delete Work Day" class="float-right"
                  @click="deleteWorkDay(workDay)">
                  <font-awesome-icon icon="window-close" />
                </span>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-header>
    </b-card>
    <b-card v-show="showRoutes">
      <b-card-body body-class="myCardBody" style="padding-top: 0px; padding-bottom: 0px">
        <transition-group name="fade-left">
          <template v-for="route in filteredRoutes">
            <DriverRoutePanel :route="route" :driver="driver" :key="route.id" />
          </template>
        </transition-group>
      </b-card-body>
    </b-card>
  </b-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DriverRoutePanel from "./DriverRoutePanel.vue";
import {
  faCaretSquareUp,
  faCaretSquareDown,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

export default {
  components: {
    DriverRoutePanel,
  },
  data() {
    return {
      showRoutes: true,
      timerHandle: null,
      totalTime: moment.duration(0),
      activityTime: moment.duration(0),
      waitTime: moment.duration(0),
      hideEnded: false,
    };
  },
  props: {
    workDay: Object,
  },
  computed: {
    ...mapGetters("common", ["errorMessage", "loading"]),
    ...mapGetters("routes", [
      "getDriver",
      "routesForDriver",
      "readOnly",
      "globalHideEnded",
    ]),
    driver() {
      return this.getDriver(this.workDay.driver.id);
    },
    routes() {
      return this.routesForDriver(this.driver);
    },
    filteredRoutes() {
      let f = this.routes;
      if (this.hideEnded) {
        f = f.filter((x) => x.endTime == null);
      }
      return f;
    },
    showRoutesIcon() {
      return this.showRoutes ? faCaretSquareUp : faCaretSquareDown;
    },
    showRoutesTitle() {
      return this.showRoutes ? "Hide Routes" : "Show Routes";
    },
    workDayStarted() {
      return this.routes.find((r) => r.startTime != null) != null;
    },
    displayTotalTime() {
      return this.totalTime.format("h:mm");
    },
    displayActivityTime() {
      return this.activityTime.format("h:mm");
    },
    displayWaitTime() {
      return this.waitTime.format("h:mm");
    },
  },
  watch: {
    routes(newVal) {
      if (newVal != null) {
        this.updateDurations();
      }
    },
    globalHideEnded(newVal) {
      this.hideEnded = newVal;
    },
  },
  methods: {
    ...mapActions("routes", [
      "deleteWorkDay",
      "addRoute",
      "setSelectedDriverWorkDay",
      "setSelectedDriverRoute",
    ]),
    toggleHideEnded() {
      this.hideEnded = !this.hideEnded;
    },
    doAddRoute() {
      let r = { driverWorkDay: this.workDay };
      if (this.routes.length == 0) {
        r.startLoc = "ADC";
      } else {
        let prevRoute = this.routes[this.routes.length - 1];
        r.startLoc = prevRoute.endLoc;
      }
      r.activity = "TRANSITING";
      r.truck = this.driver.defaultTruck;
      this.addRoute(r);
    },
    doAddLadingRoute() {
      this.setSelectedDriverWorkDay(this.workDay);
      this.setSelectedDriverRoute(null);
      this.$bvModal.show("addLadingRouteModal");
    },
    toggleShowRoutes() {
      this.showRoutes = !this.showRoutes;
    },
    updateDurations() {
      let tmpTotal = moment.duration(0);
      let tmpActivity = moment.duration(0);
      let tmpWait = moment.duration(0);
      let now = moment();
      if (this.routes != null) {
        this.routes.forEach((r, idx) => {
          if (r.startTime != null) {
            let start = moment(r.startTime);
            if (idx > 0) {
              let pr = this.routes[idx - 1];
              let end = moment(pr.endTime);
              let wait = moment.duration(start.diff(end));
              tmpTotal.add(wait);
              tmpWait.add(wait);
            }
            let end = r.endTime != null ? moment(r.endTime) : now;
            let act = moment.duration(end.diff(start));
            tmpTotal.add(act);
            tmpActivity.add(act);
          }
        });
      }
      this.totalTime = tmpTotal;
      this.activityTime = tmpActivity;
      this.waitTime = tmpWait;
    },
  },
  mounted() {
    this.timerHandle = window.setInterval(this.updateDurations, 1000 * 60);
  },
  beforeDestroy() {
    window.clearInterval(this.timerHandle);
  },
};
</script>

<style scoped>
.card-header {
  padding-top: 0px;
  padding-bottom: 0px;
}

.card-body {
  padding: 0em;
}

.btn {
  background-color: lightgrey;
}

.btn:hover {
  background-color: lightgreen;
}
</style>
