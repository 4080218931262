<template>
  <b-container fluid style="overflow: hidden; overflow-y: auto">
    <div v-show="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
    <b-card no-body>
      <b-card-header>
        <b-row>
          <b-col md="2">
            Drivers <span v-if="drivers != null">({{ drivers.length }})</span>
          </b-col>
          <b-col>
            <b-input-group prepend="First:">
              <b-form-input v-model="first" :formatter="nameFormat" trim></b-form-input>
            </b-input-group>
          </b-col>
          <b-col>
            <b-input-group prepend="Last:">
              <b-form-input :formatter="nameFormat" v-model="last" trim></b-form-input>
            </b-input-group>
          </b-col>
          <b-col>
            <b-input-group prepend="QCFS Id:">
              <b-form-input :formatter="lowerFormat" v-model="email" trim></b-form-input>
            </b-input-group>
          </b-col>
          <b-col>
            <b-input-group prepend="Truck:">
              <b-form-select v-model="defaultTruck" :options="trucks" text-field="item" value-field="item">
                <template #first>
                  <b-form-select-option value="" disabled>-- Select Truck --</b-form-select-option>
                </template>
              </b-form-select>
            </b-input-group>
          </b-col>
          <b-col md="1">
            <b-button :disabled="disableAdd" variant="light" size="md" @click="addClicked">
              <font-awesome-icon title="Add new driver" icon="plus" />
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body v-if="drivers != null">
        <b-row style="border-bottom: 1px solid black">
          <b-col md="1">Last Name</b-col>
          <b-col md="1">First Name</b-col>
          <b-col md="4">QCFS Id</b-col>
          <b-col md="1">Active</b-col>
          <b-col md="4">Default Truck</b-col>
          <b-col md="1">&nbsp;</b-col>
        </b-row>
        <b-row v-for="driver in drivers" :key="driver.id" class="row-striped">
          <b-col md="1">{{ driver.lastName }}</b-col>
          <b-col md="1">{{ driver.firstName }}</b-col>
          <b-col md="4">
            <b-form-input v-if="driver.active" :formatter="lowerFormat" v-model="driver.email" trim></b-form-input>
            <span v-else>{{ driver.email }}</span>
          </b-col>
          <b-col md="1"><b-checkbox v-model="driver.active" @change="toggleActive(driver)" /></b-col>
          <b-col md="4">
            <b-form-select v-if="driver.active" v-model="driver.defaultTruck" :options="trucks" value-field="item"
              text-field="item">
              <template #first>
                <b-form-select-option value="" disabled>-- Select Truck --</b-form-select-option>
              </template>
            </b-form-select>
            <span v-else>{{ driver.defaultTruck }}</span>
          </b-col>
          <b-col md="1">
            <b-button v-if="driver.active" variant="light" size="md" @click="updateClicked(driver)">
              <font-awesome-icon title="Update" icon="check" />
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <LoadingSplash />
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LoadingSplash from "../LoadingSplash.vue";
import textutils from "../../textutils";

export default {
  components: {
    LoadingSplash,
  },
  data() {
    return {
      first: null,
      last: null,
      email: null,
      defaultTruck: null
    };
  },
  computed: {
    ...mapGetters("common", ["errorMessage"]),
    ...mapGetters("drivers", ["drivers", "trucks"]),
    disableAdd() {
      return (
        this.first == null ||
        this.first.length == 0 ||
        this.last == null ||
        this.last.length == 0
      );
    },
  },
  methods: {
    ...mapActions("drivers", ["loadDrivers", "updateDriver", "addDriver"]),
    async addClicked() {
      let d = {
        firstName: this.first,
        lastName: this.last,
        email: this.email,
        defaultTruck: this.defaultTruck,
        active: true,
      };
      await this.addDriver(d);
      this.first = null;
      this.last = null;
      this.email = null;
      this.defaultTruck = null;
    },
    updateClicked(driver) {
      this.updateDriver(driver);
    },
    toggleActive(driver) {
      this.updateDriver(driver);
    },
    nameFormat(s) {
      if (s && s.length > 0) {
        s = `${s.substring(0, 1).toUpperCase()}${s.substring(1).toLowerCase()}`;
      }
      return s;
    },
    upperFormat(s) {
      return textutils.toUpper(s);
    },
    lowerFormat(s) {
      return textutils.toLower(s);
    }
  },
  mounted() {
    this.loadDrivers();
  },
};
</script>
